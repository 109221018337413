export default function RepostedIconWhite(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" transform="rotate(90)" {...props}>
  <linearGradient id="a" x1="-248" x2="-238" y1="-234" y2="-234" gradientTransform="rotate(180 -112 -112)" gradientUnits="userSpaceOnUse">
    <stop offset=".266" stopColor="#ffffff"/>
    <stop offset=".582" stopColor="#ffffff"/>
    <stop offset=".745" stopColor="#ffffff"/>
    <stop offset=".873" stopColor="#ffffff"/>
    <stop offset=".982" stopColor="#ffffff"/>
    <stop offset="1" stopColor="#ffffff"/>
  </linearGradient>
  <path fill="url(#a)" d="M14 13h9a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-9v6z"/>
  <linearGradient id="b" x1="3.924" x2="17.001" y1="8.199" y2="41.867" gradientUnits="userSpaceOnUse">
    <stop offset="0" stopColor="#ffffff"/>
    <stop offset="1" stopColor="#ffffff"/>
  </linearGradient>
  <path fill="url(#b)" d="M18.19 32H14V7l-4.828 4.828A4 4 0 0 0 8 14.657V32H3.81c-.72 0-1.08.87-.571 1.379L9.94 40.08a1.5 1.5 0 0 0 2.121 0l6.701-6.701c.509-.509.148-1.379-.572-1.379z"/>
  <linearGradient id="c" x1="-365" x2="-355" y1="-231.472" y2="-231.472" gradientTransform="translate(389 269.472)" gradientUnits="userSpaceOnUse">
    <stop offset=".266" stopColor="#ffffff"/>
    <stop offset=".582" stopColor="#ffffff"/>
    <stop offset=".745" stopColor="#ffffff"/>
    <stop offset=".873" stopColor="#ffffff"/>
    <stop offset=".982" stopColor="#ffffff"/>
    <stop offset="1" stopColor="#ffffff"/>
  </linearGradient>
  <path fill="url(#c)" d="M34 35h-9a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h9v-6z"/>
  <linearGradient id="d" x1="32.313" x2="44" y1="7.663" y2="40.775" gradientUnits="userSpaceOnUse">
    <stop offset="0" stopColor="#ffffff"/>
    <stop offset="1" stopColor="#ffffff"/>
  </linearGradient>
  <path fill="url(#d)" d="M29.81 16H34v25l4.828-4.828A4.001 4.001 0 0 0 40 33.344V16h4.19c.72 0 1.08-.87.571-1.379l-6.7-6.701a1.5 1.5 0 0 0-2.121 0l-6.701 6.701A.807.807 0 0 0 29.81 16z"/>
</svg>
  );
}
