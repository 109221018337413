import { useEffect, useState } from 'react';
import Link from 'next/link';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';
import {
  CursorClickIcon,
  MailOpenIcon,
  UsersIcon
} from '@heroicons/react/outline';

import { usePosts } from '../../utils/usePosts';
import { useUser } from '../../utils/useUser';

import { supabase } from '../../utils/supabase-client';
import RepostedIconWhite from '../../components/icons/RepostedIconWhite';

export default function Dashboard() {
  const { userDetails } = useUser();

  const [reposted, setReposted] = useState(0);

  async function countReposted(userId) {
    const { error, count } = await supabase
      .from('reposted')
      .select('original_id', { count: 'exact', head: true })
      .eq('user_id', userId);

    if (count > 0) [setReposted(count)];
  }

  useEffect(() => {
    if (userDetails?.user_id) {
      countReposted(userDetails.user_id);
    }
  }, [userDetails]);

  return (
    <section>
      <h1 className="text-3xl font-semibold text-gray-900 mb-10">Dashboard</h1>

      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Your stats
        </h3>

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
            <dt>
              <div className="absolute bg-gray-50 border border-gray-300 rounded-md p-3">
                <RepostedIconWhite
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                Total reposted
              </p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{reposted}</p>
              <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <Link href="/app/posts">
                    <a className="font-medium text-blue-600 hover:text-blue-500">
                      View all<span className="sr-only"> Reposted stats</span>
                    </a>
                  </Link>
                </div>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </section>
  );
}
